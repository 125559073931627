.odsImg {
  display: block;
  margin: 50px auto 0;
}

.objetivos {
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}
@media (max-width: 900px) {
  .objetivos {
    display: grid;
    grid-template-rows: repeat(auto-fill, 53px);
    grid-template-columns: repeat(auto-fill, 50px);
  }
}

.objetivos img {
  max-height: 80px;
}

@media (max-width: 1100px) {
  .objetivos img {
    max-height: 50px;
  }
  .odsImg {
    max-width: 90%;
  }
}

/* .objetivos {
  margin-left: 10px;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-gap: 1%;
} */
