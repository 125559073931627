.actions {
  /* background: url('../../assets/fundo_actions.png');
        background-size: 100%; 
        background-repeat: no-repeat; 
        background-position: center; */
  /* margin-top: 150px;
    height: 585px; */
  margin: 35px;
}

.actions div {
  flex: 1;
}

@media (max-width: 768px) {
  .actions div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.actions h2 {
  text-align: center;
  color: #1976d2;
  font-weight: 400;
  padding-top: 50px;
  margin-bottom: 70px;
}

.actions span {
  display: block;
}

.actions img {
  /* width: 75px; */
  height: 75px;
}
