.galleryTitle {
  text-align: center;
  color: #388e3c;
  font-weight: 400;
  margin: 70px auto;
}

.row {
  display: flex;
  height: 300px;
  /* box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, .22) */
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    height: 600px;
  }
  .card {
    border-radius: 0 !important;
  }
}

.row span {
  font-weight: 800;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
  /* background-color: #1a642475; */
  padding: 2px;
}

.card {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  margin: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hospedagem {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-1.png);
  border-radius: 20px 0 0 0;
}
.passaros {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-2.png);
}
.corrida {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-3.png);
  border-radius: 0 20px 0 0;
}
.piscinas {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-4.png);
}
.sonhos {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-5.png);
}
.natureza {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-6.png);
}
.fauna {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-7.png);
  border-radius: 0 0 0 20px;
}
.ciclismo {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-8.png);
}
.vooDuplo {
  flex: 1;
  background-image: url(../../assets/mosaico-HOME-9.png);
  border-radius: 0 0 20px 0;
}
