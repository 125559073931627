footer {
  background-color: #fff;
}

.footer-container {
  display: flex !important;
  background-color: #466dc6;
  color: #fff;
  font-size: 14px;
  max-width: 100% !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .container-top,
  .container-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  .container-bottom a {
    width: 100%;
  }
  .coordinates {
    padding: 3%;
  }
  .tels {
    flex-direction: column;
  }
  .tels a {
    align-items: flex-start;
  }
}

.footer-container a {
  font-size: 14px;
  margin: 0;
  padding: 3%;
}

.footer-container li {
  font-size: 14px;
  margin-bottom: 15px !important;
}
.container-top {
  margin-top: 80px;
  margin-right: 80px;
}
.container-bottom {
  margin-right: 80px;
}
.container-bottom a {
  padding: 2%;
}

footer h2 {
  font-size: 15px;
  color: #fff;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 40px;
}

footer h2 span {
  border-bottom: 1px solid #fff;
  padding: 4px 35px;
}

.img-container {
  flex: 1.2 1;
}

.img-container img {
  width: 90%;
  height: auto;
  /* max-width: 250px; */
}

@media (max-width: 820px) {
  .social {
    margin-right: 25px !important;
  }
  .img-container {
    display: none;
  }
  .container-top,
  .container-bottom {
    margin: 20px;
  }
}

.sub-tel {
  font-size: 12px;
  margin: 0;
}
