.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 330px;
  /* width: 100%;  */
  padding: 20px;
}

@media (max-width: 420px) {
  .container {
    background-position: left;
    background-size: 200%;
    height: 370px;
  }
}

.titleContainer {
  text-align: center;
  color: #0b6641;
}

.titleContainer span:first-child {
  display: block;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .titleContainer span:first-child {
    display: block;
    font-size: 18px;
    font-weight: 700;
  }
}
