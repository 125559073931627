.mtbImg,
.vooImg,
.slackImg,
.cascadingImg {
  width: 100%;
  margin-top: 10px;
}

.linkMapa {
  font-weight: 400 !important;
}
.linkMapa:hover {
  text-decoration: underline;
  font-weight: 500 !important;
}

.linkMapa:last-child {
  margin-bottom: 20px;
}
