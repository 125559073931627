.titulo {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}

hr {
    opacity: 0.3;
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
}

@media(min-width: 550px) {
    .titulo {
        text-align: start;
    }

    hr {
        width: 70%;
    }
}

.subtitulo {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}

.descricao {
    margin-top: 5px;
    text-align: justify;
}

.linkMapa {
    display: block;
    text-decoration: none;
    color: #000;
    font-weight: 700;
    margin-top: 15px;
}

.centerText {
    text-align: center;
}