.imgVoluntariado {
    width: 100%;
    margin-bottom: 50px;
}

.educacaoPage p {
    margin-bottom: 30px;
}

.educacaoPage h2 {
    margin-top: 50px;
}