.linkEmbrapa {
    color: black;
}

.imgAgro {
    height: 160px;
    width: 100%;
}

.feira {
    font-weight: 700;
    margin: 55px 0;
}