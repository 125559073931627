.fazendaBrancoImg {
  float: right;
  width: 60%;
  margin-left: 30px;
}

.subtitulo {
  font-weight: 600;
}

.container-logos-fazenda {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.container-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}
.container-buttons a {
  padding: 5px 20px;
  color: #81c784;
  border: 1px solid #81c784;
  text-align: center;
}
