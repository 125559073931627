.container-logos {
  background: url("../../assets/fundo_logos.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: space-evenly;
  height: 300px;
  object-fit: contain;
  padding: 80px 18%;
}
@media (max-width: 1054px) {
  .container-logos {
    height: unset;
    padding: 0;
  }
}
