.imgTurismoPage {
  width: 100%;
}

.linkMapa {
  display: block;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  margin-top: 15px;
}
