.imgAbrigo {
    width: 100%;
}

.abrigoImgContainer {
    margin-top: -25px !important;
    margin-bottom: 45px !important;
}

.link {
    margin-top: 50px !important;
    margin-bottom: 40px !important;
}

.logoAbrigo {
    display: block;
    margin: 0 auto 30px;
    width: 215px;
    height: 215px;
}